import { VariantProps, cva } from "class-variance-authority";

type CvaParams<T> = Parameters<typeof cva<T>>;

type ClassVariantsBase<T> = CvaParams<T>[0];
type ClassVariantsConfig<T> = Exclude<CvaParams<T>[1], undefined>;

export function classVariants<T>(
  base: ClassVariantsBase<T>,
  config: ClassVariantsConfig<T>
) {
  const cvaVariants = cva<T>(base, config);

  type CvaVariantParams = VariantProps<typeof cvaVariants>;

  type ClassVariantReturnParams = {
    [Property in keyof CvaVariantParams]:
      | Array<CvaVariantParams[Property]>
      | CvaVariantParams[Property];
  };

  return (params: ClassVariantReturnParams) => {
    let classesFromArrays = "";
    (Object.keys(params) as Array<keyof CvaVariantParams>).map((key) => {
      const value = params[key];
      if (Array.isArray(value)) {
        classesFromArrays +=
          " " +
          (value as Array<CvaVariantParams[keyof CvaVariantParams]>)
            .map((prop) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              return cvaVariants({ [key]: prop } as any);
            })
            .join(" ");
        params[key] = null as CvaVariantParams[keyof CvaVariantParams];
      }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const allClasses = classesFromArrays + " " + cvaVariants(params as any);
    return [...new Set(allClasses.split(" "))].join(" ");
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type VariantParams<Component extends (...args: any) => any> =
  Parameters<Component>[0];

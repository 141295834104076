import React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { cn } from "../../utils";
import { Check } from "../../../icons";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      `focus-visible:ring-ring peer h-4 w-4 shrink-0 rounded-sm border
      border-secondary-600 ring-offset-background focus-visible:outline-none
      focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed
      disabled:opacity-50 radix-state-checked:bg-secondary-600
      radix-state-checked:text-secondary-foreground`,
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="h-4 w-4" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };

import * as React from "react";
import { cn, classVariants, type VariantParams } from "../../utils";
import { type Icon } from "../../../icons";

const alertVariants = classVariants("relative rounded-lg border p-4", {
  variants: {
    variant: {
      default: "bg-white text-foreground",
      destructive: "border-destructive/50 bg-destructive-50 text-destructive",
      info: "border-blue/50 bg-blue-50 text-blue",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type AlertParams = React.HTMLAttributes<HTMLDivElement> &
  VariantParams<typeof alertVariants> & {
    title: string;
    description?: string;
    Icon?: Icon;
  };

const Alert = React.forwardRef<HTMLDivElement, AlertParams>(
  (
    { className, variant, children, title, description, Icon, ...props },
    ref
  ) => (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant }), className)}
      {...props}
    >
      <span className="flex items-start gap-2">
        {Icon && <Icon className="size-5 shrink-0" />}
        <div>
          <div className="mb-2 font-medium leading-none tracking-tight">
            {title}
          </div>
          <div className="text-sm [&_p]:leading-relaxed">
            {children ? children : description}
          </div>
        </div>
      </span>
    </div>
  )
);
Alert.displayName = "Alert";

export { Alert };

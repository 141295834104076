import React from "react";
import { CheckIcon, PlusIcon } from "@suns/design-system/icons";

import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../Command/Command";
import { Button } from "../Button/Button";
import { Separator } from "../Separator/Separator";
import { Text } from "../Text/Text";
import { Badge } from "../Badge/Badge";
import { cn } from "../../utils";

interface FilterProps {
  title?: string;
  value: string[];
  onChange: (keys: string[]) => void;
  options: {
    key: string;
    name: string;
  }[];
  avoidCollisions?: boolean;
}

const Filter = React.forwardRef<HTMLButtonElement, FilterProps>(
  ({ title, value = [], onChange, options, avoidCollisions }, ref) => {
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="my-3" ref={ref}>
            <PlusIcon className="mr-2 size-4" />
            <Text size="sm">{title}</Text>
            <Badge
              variant="secondary"
              className="ml-2 rounded-md px-2 font-normal lg:hidden"
            >
              {value?.length}
            </Badge>
            {value?.length > 0 && (
              <>
                <Separator orientation="vertical" className="mx-2 h-4" />
                <div className="hidden space-x-1 lg:flex">
                  {value.length > 3 ? (
                    <Badge
                      variant="secondary"
                      className="rounded-md px-2 font-normal "
                    >
                      {value.length} selected
                    </Badge>
                  ) : (
                    options
                      .filter((option) => value?.includes(option.key))
                      .map((option) => (
                        <Badge
                          variant="secondary"
                          key={option.key}
                          className="rounded-md px-2 font-normal "
                        >
                          {option.name}
                        </Badge>
                      ))
                  )}
                </div>
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          avoidCollisions={avoidCollisions}
          className="w-[200px] p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder="Search" />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = value?.includes(option.key);
                  return (
                    <CommandItem
                      key={option.key}
                      onSelect={() => {
                        if (isSelected) {
                          onChange(value.filter((val) => val !== option.key));
                        } else {
                          onChange([...value, option.key]);
                        }
                      }}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-black",
                          isSelected
                            ? "bg-secondary text-secondary-foreground"
                            : "opacity-50 [&_svg]:invisible"
                        )}
                      >
                        <CheckIcon />
                      </div>
                      <span>{option.name}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {value?.length > 0 && (
                <>
                  <Separator />
                  <CommandGroup>
                    <CommandItem
                      onSelect={() => onChange([])}
                      className="justify-center text-center"
                    >
                      Clear tags
                    </CommandItem>
                  </CommandGroup>
                </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

Filter.displayName = "Filter";

export { Filter };

import React from "react";

import {
  classVariants,
  cn,
  responsiveVariants,
  type VariantParams,
} from "../../utils";

export const variants = {
  columns: {
    "2": "grid-cols-2",
    "3": "grid-cols-3",
    "4": "grid-cols-4",
    "5": "grid-cols-5",
  },
  justify: {
    start: "justify-items-start",
    end: "justify-items-end",
    center: "justify-items-center",
    stretch: "justify-items-stretch",
  },
  align: {
    start: "items-start",
    end: "items-end",
    center: "items-center",
    baseline: "items-baseline",
    stretch: "items-stretch",
  },
  gap: {
    xs: "gap-xs",
    sm: "gap-sm",
    md: "gap-md",
    lg: "gap-lg",
    xl: "gap-xl",
  },
};

const gridVariants = classVariants(["grid w-full"], {
  variants: {
    ...variants,
    columns: {
      ...variants.columns,
      ...responsiveVariants("sm", variants.columns),
      ...responsiveVariants("md", variants.columns),
      ...responsiveVariants("lg", variants.columns),
      ...responsiveVariants("xl", variants.columns),
    },
  },
});

type GridParams = React.HTMLAttributes<HTMLElement> &
  VariantParams<typeof gridVariants>;

export function Grid({
  className,
  align,
  columns,
  gap,
  justify,
  children,
  ...props
}: GridParams) {
  return (
    <div
      className={cn(
        gridVariants({
          align,
          columns,
          gap,
          justify,
        }),
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
}

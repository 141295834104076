// See default values for override reference
// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/config.full.js
import type { Config } from "tailwindcss";
import defaultColors from "tailwindcss/colors";
import defaultTheme from "tailwindcss/defaultTheme";

const colors = {
  orange: {
    50: "#fff5ed",
    100: "#ffe7d5",
    200: "#fecbaa",
    300: "#fea773",
    400: "#fc773b",
    500: "#fa4b0a",
    600: "#eb390b",
    700: "#c3270b",
    800: "#9b2111",
    900: "#7d1e11",
    950: "#430c07",
  },
  purple: {
    50: "#eeeeff",
    100: "#e1e0ff",
    200: "#c9c6ff",
    300: "#aca4fd",
    400: "#927ffa",
    500: "#8361f3",
    600: "#7744e7",
    700: "#6736cc",
    800: "#542fa4",
    900: "#3b266e",
    950: "#2a1a4c",
  },
};

export const theme = {
  extend: {
    colors: {
      background: defaultColors.gray[100],
      foreground: defaultColors.gray[900],
      red: {
        ...defaultColors.red,
        DEFAULT: defaultColors.red[600],
      },
      orange: {
        ...colors.orange,
        DEFAULT: colors.orange[500],
      },
      yellow: {
        ...defaultColors.yellow,
        DEFAULT: defaultColors.yellow[600],
      },
      green: {
        ...defaultColors.green,
        DEFAULT: defaultColors.green[600],
      },
      blue: {
        ...defaultColors.blue,
        DEFAULT: defaultColors.blue[600],
      },
      purple: {
        ...colors.purple,
        DEFAULT: colors.purple[500],
      },
      primary: {
        ...colors.orange,
        DEFAULT: colors.orange[500],
        foreground: defaultColors.white,
      },
      secondary: {
        ...defaultColors.purple,
        DEFAULT: colors.purple[900],
        foreground: defaultColors.white,
      },
      input: {
        ...defaultColors.gray,
        DEFAULT: defaultColors.gray[100],
        placeholder: defaultColors.gray[500],
        foreground: defaultColors.gray[800],
      },
      muted: {
        ...defaultColors.gray,
        DEFAULT: defaultColors.gray[200],
        foreground: defaultColors.gray[600],
      },
      card: {
        ...defaultColors.gray,
        DEFAULT: defaultColors.white,
        foreground: defaultColors.gray[900],
      },
      popover: {
        ...defaultColors.gray,
        DEFAULT: defaultColors.white,
        foreground: defaultColors.gray[900],
      },
      accent: {
        ...defaultColors.gray,
        DEFAULT: defaultColors.gray[100],
        foreground: defaultColors.gray[900],
      },
      destructive: {
        ...defaultColors.red,
        DEFAULT: defaultColors.red[600],
        foreground: defaultColors.white,
      },
    },
    keyframes: {
      "accordion-down": {
        from: { height: "0" },
        to: { height: "var(--radix-accordion-content-height)" },
      },
      "accordion-up": {
        from: { height: "var(--radix-accordion-content-height)" },
        to: { height: "0" },
      },
      "ping-small": {
        "75%, 100%": {
          transform: "scale(1.3)",
          opacity: "0",
        },
      },
    },
    animation: {
      "accordion-down": "accordion-down 0.2s ease-out",
      "accordion-up": "accordion-up 0.2s ease-out",
      "ping-small": "ping-small 1s cubic-bezier(0, 0, 0.2, 1) infinite",
    },
    spacing: {
      ...defaultTheme.spacing,
      xs: defaultTheme.spacing[1],
      sm: defaultTheme.spacing[2],
      md: defaultTheme.spacing[4],
      lg: defaultTheme.spacing[8],
      xl: defaultTheme.spacing[16],
    },
    screens: {
      xs: "360px",
    },
  },
} satisfies Config["theme"];

import React from "react";
import { EditorState } from "lexical";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListItemNode, ListNode } from "@lexical/list";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { UNORDERED_LIST } from "@lexical/markdown";
import { cn } from "../../utils";

const EDITOR_THEME = {
  list: {
    nested: {
      listitem: "list-none",
    },
    ul: "list-disc px-8 [&_ul]:list-[revert]",
  },
};

export interface TextEditorProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onChange"> {
  defaultValue?: string;
  onChange?: (value: string | null) => void;
  placeholder?: string;
  namespace?: string;
  readonly?: boolean;
}

export const TextEditor = React.forwardRef<HTMLDivElement, TextEditorProps>(
  (
    {
      defaultValue,
      onChange,
      className,
      placeholder = "Enter notes...",
      namespace = "Editor",
      readonly = false,
      ...props
    },
    ref
  ) => {
    function handleOnChange(editorState: EditorState) {
      if (editorState.isEmpty()) {
        onChange?.(null);
        return;
      }

      const editorStateJSON = JSON.stringify(editorState.toJSON());
      onChange?.(editorStateJSON);
    }

    const initialConfig = {
      theme: EDITOR_THEME,
      namespace: namespace ?? "Editor",
      onError(error: Error) {
        throw error;
      },
      nodes: [ListNode, ListItemNode],
      editorState: defaultValue,
      editable: !readonly,
    };

    return (
      <div className="relative" ref={ref} {...props}>
        <LexicalComposer initialConfig={initialConfig}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={cn(
                  `h-72 resize-y overflow-auto rounded-md border border-input-300 bg-white px-3
                  py-2 text-input-foreground shadow-sm outline-none`,
                  className,
                  readonly
                    ? "h-auto resize-none border-none p-0 shadow-none"
                    : undefined
                )}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
            placeholder={
              readonly ? null : (
                <div className="pointer-events-none absolute left-0 top-0 px-3 py-2 text-input-placeholder">
                  {placeholder}
                </div>
              )
            }
          />
          <HistoryPlugin />
          <ListPlugin />
          <TabIndentationPlugin />
          <OnChangePlugin onChange={handleOnChange} />
          <MarkdownShortcutPlugin transformers={[UNORDERED_LIST]} />
        </LexicalComposer>
      </div>
    );
  }
);

TextEditor.displayName = "TextEditor";

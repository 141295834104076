import React from "react";
import { X } from "../../../icons";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { cn } from "../../utils";
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      `fixed inset-0 z-50 bg-black/80 radix-state-closed:animate-out
      radix-state-closed:fade-out-0 radix-state-open:animate-in
      radix-state-open:fade-in-0`,
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPrimitive.Portal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        `fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%]
        translate-y-[-50%] gap-4 border bg-background p-6 shadow-lg duration-200
        radix-state-closed:animate-out radix-state-closed:fade-out-0
        radix-state-closed:zoom-out-95 radix-state-closed:slide-out-to-left-1/2
        radix-state-closed:slide-out-to-top-[48%] radix-state-open:animate-in
        radix-state-open:fade-in-0 radix-state-open:zoom-in-95
        radix-state-open:slide-in-from-left-1/2 radix-state-open:slide-in-from-top-[48%]
        sm:rounded-lg`,
        className
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close
        className="focus:ring-ring absolute right-4 top-4 rounded-sm opacity-70
          ring-offset-background transition-opacity hover:opacity-100 focus:outline-none
          focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none
          radix-state-open:bg-accent radix-state-open:text-muted-foreground"
      >
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPrimitive.Portal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";
const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "text-lg font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogTrigger, DialogContent, DialogTitle, DialogDescription };
